@use '@angular/material' as mat;
@mixin theme-emitter($theme) {

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  *,
  app-theme-emitter {
    .primary {
      color: mat.get-color-from-palette($primary);
    }

    .accent {
      color: mat.get-color-from-palette($accent);
    }

    .warn {
      color: mat.get-color-from-palette($warn);
    }
  }
}
