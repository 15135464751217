@font-face {
  font-family: Circular;
  src: url('../assets/fonts/circular/CircularStd-Book.WOFF') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: Circular;
  src: url('../assets/fonts/circular/CircularStd-Medium.WOFF') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: Circular;
  src: url('../assets/fonts/circular/CircularStd-Bold.WOFF') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: Circular;
  src: url('../assets/fonts/circular/CircularStd-Black.WOFF') format('woff');
  font-weight: 900;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/avenir/light/Avenir-Light.woff2') format('woff2');
  font-weight: 200;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/avenir/book/Avenir-Book.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/avenir/roman/Avenir-Roman.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/avenir/medium/Avenir-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/avenir/heavy/Avenir-Heavy.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/avenir/black/Avenir-Black.woff2') format('woff2');
  font-weight: 900;
}
