/* stylelint-disable color-hex-length */

// $brand-blue-primary: rgba(24, 53, 94, 1);
$md-blueprimary: (50 : #e3e7ec,
100 : #bac2cf,
200 : #8c9aaf,
300 : #5d728e,
400 : #3b5376,
500 : #18355e,
600 : #153056,
700 : #11284c,
800 : #0e2242,
900 : #081631,
A100 : #6b95ff,
A200 : #3870ff,
A400 : #054bff,
A700 : #0042eb,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #ffffff,
400 : #ffffff,
500 : #ffffff,
600 : #ffffff,
700 : #ffffff,
800 : #ffffff,
900 : #ffffff,
A100 : #000000,
A200 : #ffffff,
A400 : #ffffff,
A700 : #ffffff,
));

// $brand-blue-accent: rgba(172, 199, 215, 1);
$md-blueaccent: (50 : #f5f8fa,
100 : #e6eef3,
200 : #d6e3eb,
300 : #c5d8e3,
400 : #b8cfdd,
500 : #acc7d7,
600 : #a5c1d3,
700 : #9bbacd,
800 : #92b3c7,
900 : #82a6be,
A100 : #ffffff,
A200 : #ffffff,
A400 : #e3f4ff,
A700 : #c9eaff,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #000000,
500 : #000000,
600 : #000000,
700 : #000000,
800 : #000000,
900 : #000000,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

// $brand-green-primary: rgba(6, 52, 36, 1);
$md-greenprimary: (50 : #e1e7e5,
100 : #b4c2bd,
200 : #839a92,
300 : #517166,
400 : #2b5245,
500 : #063424,
600 : #052f20,
700 : #04271b,
800 : #032116,
900 : #02150d,
A100 : #55ffaa,
A200 : #22ff90,
A400 : #00ee77,
A700 : #00d46a,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #ffffff,
400 : #ffffff,
500 : #ffffff,
600 : #ffffff,
700 : #ffffff,
800 : #ffffff,
900 : #ffffff,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

// $brand-green-accent: rgba(202, 230, 208, 1);
$md-greenaccent: (50 : #f9fcf9,
100 : #eff8f1,
200 : #e5f3e8,
300 : #daeede,
400 : #d2ead7,
500 : #cae6d0,
600 : #c5e3cb,
700 : #bddfc4,
800 : #b7dbbe,
900 : #abd5b3,
A100 : #ffffff,
A200 : #ffffff,
A400 : #ffffff,
A700 : #ffffff,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #000000,
500 : #000000,
600 : #000000,
700 : #000000,
800 : #000000,
900 : #000000,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

// $brand-red-primary: rgba(214, 11, 40, 1);
$md-redprimary: (50 : #fae2e5,
100 : #f3b6bf,
200 : #eb8594,
300 : #e25469,
400 : #dc3048,
500 : #d60b28,
600 : #d10a24,
700 : #cc081e,
800 : #c60618,
900 : #bc030f,
A100 : #ffe5e6,
A200 : #ffb2b4,
A400 : #e57373,
A700 : #ff656a,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #ffffff,
500 : #ffffff,
600 : #ffffff,
700 : #ffffff,
800 : #ffffff,
900 : #ffffff,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

// $brand-red-accent: rgba(252, 222, 225, 1);
$md-redaccent: (50 : #fffbfb,
100 : #fef5f6,
200 : #feeff0,
300 : #fde8ea,
400 : #fce3e6,
500 : #fcdee1,
600 : #fcdadd,
700 : #fbd5d9,
800 : #fbd1d5,
900 : #fac8cd,
A100 : #ffffff,
A200 : #ffffff,
A400 : #ffffff,
A700 : #ffffff,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #000000,
500 : #000000,
600 : #000000,
700 : #000000,
800 : #000000,
900 : #000000,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

// $brand-orange-primary: rgba(243, 111, 57, 1);
$md-orangeprimary: (50 : #feeee7,
100 : #fbd4c4,
200 : #f9b79c,
300 : #f79a74,
400 : #f58557,
500 : #f36f39,
600 : #f16733,
700 : #ef5c2c,
800 : #ed5224,
900 : #ea4017,
A100 : #ffffff,
A200 : #ffece8,
A400 : #ffc1b5,
A700 : #ffac9c,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #000000,
500 : #000000,
600 : #000000,
700 : #000000,
800 : #ffffff,
900 : #ffffff,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

// $brand-orange-accent: rgba(242, 234, 60, 1);
$md-orangeaccent: (50 : #fdfce8,
100 : #fbf9c5,
200 : #f9f59e,
300 : #f6f077,
400 : #f4ed59,
500 : #f2ea3c,
600 : #f0e736,
700 : #eee42e,
800 : #ece127,
900 : #e8db1a,
A100 : #ffffff,
A200 : #fffde8,
A400 : #fffab5,
A700 : #fff89c,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #000000,
500 : #000000,
600 : #000000,
700 : #000000,
800 : #000000,
900 : #000000,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

$md-redprimaryalt: (50 : #ffebea,
100 : #fecdca,
200 : #feaca7,
300 : #fd8a83,
400 : #fc7169,
500 : #fc584e,
600 : #fc5047,
700 : #fb473d,
800 : #fb3d35,
900 : #fa2d25,
A100 : #ffffff,
A200 : #ffffff,
A400 : #ffcfce,
A700 : #ffb7b4,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #000000,
500 : #000000,
600 : #000000,
700 : #ffffff,
800 : #ffffff,
900 : #ffffff,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));
/* stylelint-enable */
