/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '../../../assets/fontawesome/css/all.min.css';
@import './scss/palette.scss';
@import './scss/typography.scss';
@import './scss/theme/blackbird-brand-theme-palette.scss';
@import './scss/theme/blackbird-dark-theme.scss';
@import './scss/theme/blackbird-light-theme.scss';
@import './scss/theme/theme-emitter.scss';
@import './scss/base.scss';
@import './scss/admin.scss';
@import './scss/alert';

@import 'tailwindcss/base';
@import 'tailwindcss/utilities';

@include mat.core();

@include mat.all-component-typographies($admin-typography-default);
// @include mat.all-component-typographies($admin-typography-large);

.blackbird-light-theme {
  @include mat.all-component-themes($blackbird-light-theme);
  @include theme-emitter($blackbird-light-theme);
}

.blackbird-dark-theme {
  @include mat.all-component-themes($blackbird-dark-theme);
  @include theme-emitter($blackbird-dark-theme);
}
