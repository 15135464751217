//
// Base styles
//
@import "border-radius";

@mixin alert-variant($background, $border, $body-color) {
  background-color: $background;
  border-color: $border;
  color: $body-color;

  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($body-color, 10%);
  }
}

$alert-padding: 15px;
$spacer: pxToRem(16px);
$spacer-x: $spacer;
$spacer-y: $spacer;
$alert-border-width: 1px;
$alert-border-radius: pxToRem(3px);

$color-green-100: #e7faf2;
$color-green-600: #46be8a !default;
$brand-success-100: $color-green-100;
$brand-success: $color-green-600;
$alert-success-bg: rgba($brand-success-100, (80/100));
$alert-success-text: $brand-success;
$alert-success-border: $brand-success-100;

$color-cyan-100: #ecf9fa;
$color-cyan-600: #57c7d4;
$brand-info: $color-cyan-600;
$brand-info-100: $color-cyan-100;
$alert-info-bg: rgba($brand-info-100, (80/100));
$alert-info-text: $brand-info;
$alert-info-border: $brand-info-100;

$color-orange-100: #fff3e6;
$color-orange-600: #f2a654;
$brand-warning: $color-orange-600;
$brand-warning-100: $color-orange-100;
$alert-warning-bg: rgba($brand-warning-100, (80/100));
$alert-warning-text: $brand-warning;
$alert-warning-border: $brand-warning-100;

$color-red-100: #ffeaea !default;
$color-red-600: #f96868 !default;
$brand-danger: $color-red-600;
$brand-danger-100: $color-red-100;
$alert-danger-bg: rgba($brand-danger-100, (80/100));
$alert-danger-text: $brand-danger;
$alert-danger-border: $brand-danger-100;

.alert {
  padding: $alert-padding;
  margin-bottom: $spacer-y;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: 700;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: ($alert-padding * 2);

  // Adjust close link position
  .close {
    position: relative;
    top: -.125rem;
    right: -$alert-padding;
    color: inherit;
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}
.alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}
.alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}
.alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}
