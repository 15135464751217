.cdk-overlay-pane {
  max-width: 100vw !important;
}

.mat-form-field-appearance-outline:not(.unstyled) .mat-form-field-wrapper {
  margin: unset;
  padding-bottom: unset;

  .mat-form-field-flex {
    .mat-form-field-infix {
      padding: unset;
      padding-bottom: 0.43em;
    }
  }

  .mat-form-field-subscript-wrapper {
    padding: unset;
    margin-top: unset;
  }
}

.mat-snack-bar-container.error {
  color: #d93934 !important;
}

.mat-dialog-content {
  .form-container {
    form {
      fieldset {
        background: inherit;
        border-radius: inherit;
        border: inherit;
        box-shadow: inherit;
      }
    }
  }
}

.form-container {
  form {
    fieldset {
      background: white;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
      0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }
  }
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  height: 32px !important;
  font-size: 14px !important;
}

.mat-dialog-container {
  padding: 0 !important;
}

.mat-sort-header-button {
  font-size: 16px !important;
}

.mat-option.default-option {
  font-size: 14px !important;
  height: 36px !important;
}

.blackbird-light-theme .mat-input-element::-webkit-input-placeholder,
.blackbird-light-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.62) !important;
}

.mat-admin-dialog {
  .mat-dialog-title {
    background: #18355e;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 12px 24px 8px;
    margin: 0;
  }

  .mat-dialog-content {
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 24px;
    max-height: 80vh;
  }

  .mat-dialog-actions {
    padding-left: 24px;
    padding-right: 24px;
    justify-content: flex-end;
    margin-bottom: 0;

    button {
      margin-left: 8px;
    }
  }


}

.mat-admin-drawer {
  .mat-dialog-container {
    border-radius: 0;
  }

  .mat-dialog-title {
    background: #18355e;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 12px 24px;
    margin: 0;
  }

  .modal-close-button {
    position: absolute;
    top: 14px;
    right: 20px;
    cursor: pointer;
    color: #fff
  }

  .mat-dialog-content {
    margin-left: 24px;
    margin-right: 24px;
    padding: 24px 0 0 0;
    max-height: 88vh;

    .mat-form-field {
      width: 100%;
      margin-bottom: 24px;
    }

    .action-buttons {
      .cancel {
        margin-left: 40px;
      }
    }

    table {
      width: 100%;
    }
  }
}

.mat-tooltip.description-tooltip {
  font-size: 16px !important;
}

.filters-wrapper {
  position: relative;

  .reset-filters-button {
    position: absolute;
    z-index: 1000;
    top: -15px;
    left: -15px;
  }
}

table {
  .attribute-wrapper {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 4px;
  }
}

.w-full {
  width: 100%;
}

.no-wrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.create-button {
  position: absolute !important;
  z-index: 10000;
  top: 50px;
  right: 24px;
}

.table-filters {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 10px;
  margin-bottom: 20px;

  input,
  .mat-select {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .clear-button {
    display: none;
  }
}

.table-filter-buttons {
  text-align: center;
  margin-bottom: 20px;

  button {
    display: inline-block;
  }

  .reset {
    margin-right: 50px;
  }
}

.admin-table {
  position: relative;

  table {
    width: 100%;

    th, td {
      padding: 4px 10px;
    }
  }

  .mat-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
  }

  .green {
    color: #43ac6b !important;
  }
}

.entity-form .mat-form-field-infix {
  padding: 1em 0 !important;
}

.entity-form mat-error {
  position: absolute;
  font-size: 75%;
  top: 50px;
}

