// make themed container fit screen
html,
body,
app-root,
.mat-app-background {
  height: 100%;
}

body {
  margin: 0;
  font-family: '100% Avenir', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
}

// Actually hide on [hidden]
*[hidden=''] {
  pointer-events: none !important;
  display: none !important;
  opacity: 0 !important;
}

// Override browser autocomplete styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  outline-color: red;
  border: 1px solid #0000;
  outline-style: none !important;
  outline-width: 0;
  -webkit-text-fill-color: var(--primary);
  -webkit-tap-highlight-color: transparent;

  /* -webkit-box-shadow: 0 0 0px 1000px #0000000 inset; */
  transition: background-color 5000s ease-in-out 0s;
}

// dark theme overrides
.blackbird-dark-theme {
  // Override browser autocomplete styles
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: white !important;
  }
}
